import BpkLargeCityCenterIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/city-center';
import BpkLargeFoodIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/food';
import BpkLargeHeartIcon from '@skyscanner/backpack-web/bpk-component-icon/lg/heart';

import JobList from '../../components/JobList';
import JobsAppHero from '../../components/JobsAppHero';

const GlasgowPage = () => (
  <>
    <JobsAppHero hideHero={false} bannerType="glasgow" hideHeroMobile={false} />
    <section className="grid__container">
      <div className="grid__row">
        <div className="grid__column--12 grid__column--mobile-12">
          <h1 className="offices-header">Glasgow</h1>
          <div className="offices-body">
            <p>
              Known for its brilliant live music scene, stunning architecture,
              dynamic art, welcoming people, innovation and world-class
              university, Glasgow was a clear next step in expanding
              Skyscanner’s Scotland-based team.
            </p>
            <p>
              Now with a team of around 180 across marketing, engineering,
              business, product, design, research and copywriting, we’re always
              on the lookout for exceptional talent.
            </p>
          </div>
        </div>
      </div>
      <hr aria-hidden="true" />
    </section>

    <section className="grid__container">
      <div className="grid__row content-block">
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeCityCenterIcon className="city-center-icon--large" />
          <br />
          <h2 id="life-section2-col1-header">Where we are</h2>
          <div id="life-section2-col1-body">
            <p>
              Our bright, open-plan office is located on busy West Regent
              Street, a stone’s throw from Glasgow’s Central Station and popular
              cafes, restaurants, pubs and shops.
            </p>
            <p>
              We have a large, welcoming space with free snacks and drinks, a
              meditation area, a wide selection of meeting rooms and our trusty
              ping-pong table!
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeHeartIcon className="heart-icon--blue" />
          <h2 id="life-section2-col2-header">Our culture</h2>
          <div id="life-section2-col2-body">
            <p>
              As a city, Glasgow is well known for its friendliness and
              openness, and that’s reflected in how we behave as a team. We’re
              passionate, ambitious, people-driven problem-solvers who care
              about raising the collective quality of our work.
            </p>
            <p>
              As well as impromptu team lunches or after-work outings, we have
              monthly themed socials and big, seasonal celebrations like our
              Summer BBQ.
            </p>
          </div>
        </div>
        <div className="grid__column--4 grid__column--tablet-12">
          <BpkLargeFoodIcon className="food-icon" />
          <h2 id="life-section2-col3-header">Things to do</h2>
          <div id="life-section2-col3-body">
            <p>
              A UNESCO City of Music among many other claims-to-fame, Scotland’s
              biggest city boasts a vibrant nightlife, world-class food,
              incredible art and architecture and is home to the Scottish Opera,
              Scottish Ballet and National Theatre of Scotland.
            </p>
            <p>
              Fancy a break from the buzz? Escape to one of the city’s many
              parks or take a short drive out to the stunning highlands to
              explore mountains, moors, lochs and castles.
            </p>
          </div>
        </div>
      </div>
    </section>
    <JobList location="Glasgow" />
  </>
);

export default GlasgowPage;
